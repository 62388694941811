import React, { useState } from "react"

const ToggleSlider = ({ onToggleTrue, label }) => {
  const [isMapView, setIsMapView] = useState(false)

  const handleToggle = () => {
    const newState = !isMapView
    setIsMapView(newState)
    if (onToggleTrue) {
      onToggleTrue(newState) // Notify parent component
    }
  }

  return (
    <div className="flex items-center gap-3">
      <label className="relative inline-flex items-center cursor-pointer">
        <input
          type="checkbox"
          className="sr-only peer opacity-0 hidden"
          checked={isMapView}
          onChange={handleToggle}
        />
        <div className="w-11 h-6 bg-gray-300 peer-focus:ring-2 peer-focus:ring-blue-500 rounded-full peer peer-checked:bg-[#1C5C4F] transition-all"></div>
        <div className="w-4 h-4 bg-white rounded-full shadow-md absolute top-1 left-1 peer-checked:translate-x-5 transition-transform"></div>
      </label>
      <span className="text-gray-700 text-sm">{label}</span>
    </div>
  )
}

export default ToggleSlider
