import React, { useState, useEffect } from "react"
import SectionTitle from "../../atoms/SectionTitle"
import { properties } from "../../../content/Properties"
import PropertyCard from "../../molecules/PropertyCard"
import BrandParagraph from "../../atoms/BrandParagraph"
import PropertiesOnMapSection from "../../molecules/PropertiesOnMapSection"
import ToggleSlider from "../../atoms/ToggleSlider"

const POSTS_PER_PAGE = 9

const OurPropertiesSection = ({ searchQuery, filters }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [isMapView, setIsMapView] = useState()

  useEffect(() => {
    setCurrentPage(1)
  }, [])

  const filteredPosts = properties.filter(post => {
    // Match multiple selected values for each filter (region, propertyStatus, category)
    const matchesRegion =
      filters.region.length === 0 || filters.region.includes(post.region)

    const matchesStatus =
      filters.propertyStatus.length === 0 ||
      filters.propertyStatus.includes(post.propertyStatus)

    const matchesCategory =
      filters.category.length === 0 || filters.category.includes(post.category)

    // Handle the 'Any' condition for Bedrooms and Baths, as well as 'N+' conditions
    const matchesBedrooms =
      filters.beds === "" ||
      filters.beds === "Any" || // Matches "Any"
      (filters.beds.includes("+")
        ? post.beds >= parseInt(filters.beds.replace("+", "")) // Matches "N+" condition
        : post.beds === parseInt(filters.beds)) // Matches exact bedroom count

    const matchesBaths =
      filters.baths === "" ||
      filters.baths === "Any" || // Matches "Any"
      (filters.baths.includes("+")
        ? post.baths >= parseInt(filters.baths.replace("+", "")) // Matches "N+" condition
        : post.baths === parseInt(filters.baths)) // Matches exact bath count

    // Match search query
    const matchesQuery = post.title
      .toLowerCase()
      .includes(searchQuery.toLowerCase())

    return (
      matchesRegion &&
      matchesStatus &&
      matchesCategory &&
      matchesBedrooms &&
      matchesBaths &&
      matchesQuery
    )
  })

  const totalPages = Math.ceil(filteredPosts.length / POSTS_PER_PAGE)

  const paginatedPosts = filteredPosts.slice(
    (currentPage - 1) * POSTS_PER_PAGE,
    currentPage * POSTS_PER_PAGE
  )

  const handlePageChange = pageNumber => setCurrentPage(pageNumber)
  const handlePreviousPage = () =>
    currentPage > 1 && setCurrentPage(currentPage - 1)
  const handleNextPage = () =>
    currentPage < totalPages && setCurrentPage(currentPage + 1)

  const onToggleTrue = value => {
    setIsMapView(value)
  }

  return (
    <div className="lg:mt-5 py-8 lg:pt-5 lg:pb-20" id="our_properties">
      <div className="cc-section--horizontal-space">
        <div className="flex flex-wrap justify-between gap-[30px]">
          <div>
            <SectionTitle
              title={`Properties available in <span>Australia</span>`}
              titleEC={`mt-0`}
              isTopImage={false}
              componentEC={`text-left`}
            />
            <BrandParagraph
              text={`${filteredPosts.length} properties found`}
              textEC={`text-[18px] lg:text-[24px]`}
            />
          </div>
          <ToggleSlider label={`Map View`} onToggleTrue={onToggleTrue} />
        </div>
        {paginatedPosts.length > 0 ? (
          <div
            className={` mt-10 ${
              isMapView === true
                ? "flex flex-col-reverse lg:flex-row justify-between gap-[30px]"
                : ""
            }`}
          >
            <div className={`${isMapView === true ? "lg:w-[60%]" : ""}`}>
              <div
                className="flex flex-wrap justify-start"
                style={{ rowGap: `15px` }}
              >
                {paginatedPosts.map((item, index) => (
                  <div
                    key={`resource_card_${index}`}
                    className={`w-full px-2 mt-2 ${
                      isMapView === true ? "md:w-1/2" : "md:w-1/3"
                    }`}
                  >
                    <PropertyCard data={item} />
                  </div>
                ))}
              </div>

              <div className="flex justify-center mt-16">
                <button
                  type="button"
                  onClick={handlePreviousPage}
                  disabled={currentPage === 1}
                  className={`mx-1 px-4 py-3 border border-[#BBBBBB] rounded ${
                    currentPage === 1 ? "text-[#979797]" : "text-[#000000]"
                  } transition hover:bg-primary hover:text-primary`}
                >
                  Previous
                </button>
                {Array.from({ length: totalPages }, (_, index) => (
                  <button
                    key={index}
                    type="button"
                    onClick={() => handlePageChange(index + 1)}
                    className={`mx-1 px-5 py-3 border border-[#BBBBBB] rounded ${
                      currentPage === index + 1
                        ? "bg-primary text-secondary"
                        : "text-[#000000]"
                    } transition hover:bg-primary hover:text-primary`}
                  >
                    {index + 1}
                  </button>
                ))}
                <button
                  type="button"
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                  className={`mx-1 px-4 py-3 border border-[#BBBBBB] rounded ${
                    currentPage === totalPages
                      ? "text-[#979797]"
                      : "text-[#000000]"
                  } transition hover:bg-primary hover:text-primary`}
                >
                  Next
                </button>
              </div>
            </div>
            <div className={`${isMapView === true ? "lg:w-[40%] mt-0 lg:mt-[8px]" : "hidden"}`}>
              <div className="sticky top-[110px] rounded-[12px] overflow-hidden">
                <PropertiesOnMapSection properties={filteredPosts} />
              </div>
            </div>
          </div>
        ) : (
          <p className="text-center mt-16">
            No Properties match your search query.
          </p>
        )}
      </div>
    </div>
  )
}

export default OurPropertiesSection
