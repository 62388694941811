import { faSearch } from "@fortawesome/free-solid-svg-icons"

export const propertiesPage = {
  title: `<span>Properties</span>`,
  description: `Explore a variety of high-quality, purpose-built SDA properties. Our accessible accommodations combine comfort, independence, and assistance in an environment of security and comfort. `,
  googleReviewText: `Care Casa offers safe and welcoming SDA housing with caring staff and excellent facilities. Perfect for anyone needing supportive accommodation!`,
  formDetails: {
    formFieldIcon: faSearch,
    formFieldPlaceholderText: `Search keywork or tag...`,
    buttonText: `Search`,
  },
  pageLongDescription: `
  Apprehending the intricacies of Specialist Disability Accommodation (SDA) can be overwhelming. Fortunately, Care Casa is here to make it easier for you. Our comprehensive resources cover all aspects of the process, from understanding the various types of SDA and their features to managing your NDIS plan and finding suitable accommodations to help you make informed decisions.`,
}

// Faqs Section
export const faqs = [
  {
    question: `What is specialist disability accommodation (SDA), and how does Care Casa support it?`,
    answer: `Specialist disability accommodation (SDA) refers to housing and support services designed to assist individuals with disabilities in living as independently as possible. Care Casa provides SDA housing solutions, including accessible housing options and personalised support plans that cater to each resident's unique needs.`,
  },
  {
    question: `Who is eligible to live at Care Casa?`,
    answer: `Care Casa welcomes individuals with disabilities who has NDIS plan and looking for SDA housing arrangements. Our homes are designed to accommodate various needs, and we work closely with potential residents and their families to ensure suitability and compatibility.`,
  },
  {
    question: `How does Care Casa assist with navigating the National Disability Insurance Scheme (NDIS)?`,
    answer: `We have dedicated NDIS coordination services to help residents navigate the complexities of the NDIS. Our team assists with planning, funding applications, and connecting residents with the right support and services under their NDIS plan.`,
  },
  {
    question: `What types of accommodations does Care Casa offer?`,
    answer: `Care Casa offers a variety of accommodation options tailored to meet the diverse needs of our residents. These include single-bedroom apartments, shared living arrangements, and family-style homes, all with accessibility features and designed for comfort.`,
  },
  {
    question: `What support services are available at Care Casa?`,
    answer: `Our support services are comprehensive and personalised. They include assistance with daily living activities, personal care support, community participation programs, and access to healthcare services. Each resident receives a personalised support plan based on their needs and preferences.`,
  },
  {
    question: `How does Care Casa promote community engagement among residents?`,
    answer: `We prioritise community engagement through various activities and programs. Residents can participate in social events, recreational activities, and educational workshops. These initiatives foster a sense of belonging and encourage social interaction among residents.`,
  },
  {
    question: `What safety and accessibility features are available in Care Casa homes?`,
    answer: `Our homes are designed with safety and accessibility in mind. Features include wheelchair-accessible entrances, modified bathrooms and kitchens, emergency call systems, secure parking options, and accessibility to common areas within the community.`,
  },
  {
    question: `How can I schedule a tour of Care Casa or apply for residency?`,
    answer: `We encourage anyone interested in learning about Care Casa to schedule a tour of our facilities. During the tour, you'll see our accommodations and meet our team. To apply for residency, contact us directly, and we'll guide you through the application process, including eligibility requirements and availability.`,
  },
  // More questions...
]
