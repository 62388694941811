import React, { useState, useEffect, useRef } from "react"
import { GoogleMap, Marker, LoadScript } from "@react-google-maps/api"

const PropertiesOnMapSection = ({ properties }) => {
  const [containerStyle, setContainerStyle] = useState({
    width: "100%",
    height: "82vh",
  })

  useEffect(() => {
    const updateStyle = () => {
      if (window.matchMedia("(max-width: 768px)").matches) {
        setContainerStyle({ width: "100%", height: "50vh" }) // Adjust for small screens
      } else {
        setContainerStyle({ width: "100%", height: "82vh" }) // Default
      }
    }

    updateStyle() // Initialize on component mount
    window.addEventListener("resize", updateStyle)

    return () => window.removeEventListener("resize", updateStyle)
  }, [])

  const mapRef = useRef(null)
  // Adjust bounds when map loads or toggle state changes
  useEffect(() => {
    if (mapRef.current && properties.length) {
      const bounds = new window.google.maps.LatLngBounds()

      properties.forEach(property => {
        const lat = parseFloat(property.latitude)
        const lng = parseFloat(property.longitude)
        if (!isNaN(lat) && !isNaN(lng)) {
          bounds.extend({ lat, lng })
        }
      })

      mapRef.current.fitBounds(bounds)
    }
  }, [properties]) 


  const onLoad = map => {
    mapRef.current = map // Store map instance
  }

  return (
    <LoadScript googleMapsApiKey="AIzaSyCAIwTPctnSM2PWcbK6cMdlZaSgEYIKp5U">
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={{ lat: -30.744340304499747, lng: 128.94120120163927 }} // Temporary center, will be updated by `fitBounds`
        zoom={10} // Temporary zoom, will be overridden by `fitBounds`
        onLoad={onLoad} // Adjust bounds once map is loaded
      >
        {properties.map((property, index) => {
          const lat = parseFloat(property.latitude)
          const lng = parseFloat(property.longitude)

          if (isNaN(lat) || isNaN(lng)) {
            return null
          }

          return (
            <Marker
              key={index}
              position={{ lat, lng }}
              title={property.title || "Property"}
            />
          )
        })}
      </GoogleMap>
    </LoadScript>
  )
}

export default PropertiesOnMapSection
